<template>
   <div v-if="toDay" class="podcastPage">
        <div class="header2">
            <div class="roundButton">
                <router-link :to="{name:'Schedule', params:{dayid:leftClick}}">
                    <TheIcon icon="left" :outline="false" :background="false"/>
                </router-link>
            </div>

            <div class="headerContent">
                <div v-if="toDay.dayId!='0'" class="showTitle">{{ toDay.day }}</div>
                <div v-else class="showTitle">Oggi</div>
            </div>

            <div class="roundButton">
                <router-link :to="{name:'Schedule', params:{dayid:rightClick}}">
                    <TheIcon icon="right" :outline="false" :background="false"/>
                </router-link>
            </div>
        </div>
        <div class="listContainer">
            <div v-for="item in toDay.shows" :key="item.id" class="simpleContainer">
                <div class="roundButton">
                    <TheIcon :icon="item.type" :outline="false" :background="false"/>
                </div>
                <div class="podcastData">
                    <div class="podcastTitle">{{ item.title }}</div>
                    <div class="timeSlot">{{ formatHHMM(item.start_at) }} - {{ formatHHMM(item.end_at) }}</div>
                    <div class="showDescription">{{ item.description }}</div>
                </div>
                <div v-if="item.show!=null" class="roundButton">
                    <router-link :to="{name:'Show', params:{slug: item.show.slug}}">
                        <TheIcon icon="link" :outline="false" :background="false"/>
                    </router-link>
                </div>
                <div v-else class="emptyButton">
                </div>
            </div>
        </div>
    </div>
    <div v-else class="loadingContainer"><TheLoading /></div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import TheIcon from '@/components/TheIcon'
import TheLoading from '@/components/TheLoading'

export default {
  name: 'Schedule',
  components: {
    TheIcon,
    TheLoading
  },
  data(){
    return{
      toDay: null,
      formatHHMM: function(val){
          let data=new Date(val+'+0000')
          let h = data.getHours()
          let m = data.getMinutes() ? data.getMinutes() : '00'
          return h + ':' + m
        },
    }
  },
  props:{
    dayid:{
      type: String,
      required: true
    }
  },
  mounted(){
    if(!this.$schedule){
      axios.defaults.headers['Pragma'] = 'no-cache';
      axios.defaults.headers['Cache-Control'] = 'no-cache';
      let adesso = new Date()
      adesso.setUTCHours(0,0,0,0)
      axios
        .get("/api/stations/radiogramma/schedules/?start_at__gte="+escape(adesso.toISOString()))
        .then(response => {
          let days=[]
          let chunks=[]
          response.data.map((e) => {
            let b= new Date(e.start_at)
            let c=days.indexOf(b.getDate())
            if(c>=0){
              chunks[c].push(e)
            }
            else{
              days.push(b.getDate())
              chunks.push([])
              chunks[chunks.length-1].push(e)
            }
          })
          let sched=[]
          var weekdays = new Array(7);
          weekdays[0] = "Domenica";
          weekdays[1] = "Lunedì";
          weekdays[2] = "Martedì";
          weekdays[3] = "Mercoledì";
          weekdays[4] = "Giovedì";
          weekdays[5] = "Venerdì";
          weekdays[6] = "Sabato";
          for(let i in days){
              sched.push({
                  "dayId": i,
                  "day": weekdays[new Date(chunks[i][0].start_at).getDay()] + ' ' + new Date(chunks[i][0].start_at).getDate(),
                  "shows":chunks[i],
              })
          }
          
          this.$schedule=sched.slice()
            
          if(this.$schedule[parseInt(this.dayid)]){
            
            this.toDay=this.$schedule[parseInt(this.dayid)]
          }else{
            this.$router.push({name:'NotFound', params:{error: 'Wrong dayid'}})
          }
        })
        .catch(err => {
          this.$router.push({name:'NotFound', params:{error: err.message}})
        })
    }
    else{
      if(this.$schedule[parseInt(this.dayid)]){
        this.toDay=this.$schedule[parseInt(this.dayid)]
      }else{
        this.$router.push({name:'NotFound', params:{error: 'Wrong dayid'}})
      }
    }
  },
  computed:{

    leftClick(){
        if(parseInt(this.dayid)-1<0){return (this.$schedule.length-1).toString()} else { return (parseInt(this.dayid)-1).toString() }
    },
    rightClick(){
        if(parseInt(this.dayid)+1>=this.$schedule.length){return '0'} else {   return (parseInt(this.dayid)+1).toString() }
    },
  }
}
</script>
